<template>
  <el-scrollbar>
    <el-menu
      class="sidebar"
      background-color="#2f3447"
      text-color="#ffffff"
      active-text-color="#ffffff"
      :default-active="$route.path"
      router
    >
      <!-- <el-image
        class="logo"
        :src="require('@/assets/img/logo-white.png')"
        fit="contain"
      /> -->
      <div class="title">耗材管理平台</div>
      <el-menu-item index="/index">
        <i class="el-icon-s-home" />
        <span slot="title">首页</span>
      </el-menu-item>
      <!--
      <el-menu-item index="/product">
        <i class="el-icon-s-grid" />
        <span slot="title">仪器类型</span>
      </el-menu-item>
      -->
      <el-menu-item index="/customer">
        <i class="el-icon-s-custom" />
        <span slot="title">客户管理</span>
      </el-menu-item>
      <el-menu-item index="/device">
        <i class="el-icon-cpu" />
        <span slot="title">连接管理</span>
      </el-menu-item>
      <el-menu-item index="/equipment">
        <i class="el-icon-cpu" />
        <span slot="title">仪器管理</span>
      </el-menu-item>
      
      <el-menu-item index="/asset">
        <i class="el-icon-s-help" />
        <span slot="title">耗材管理</span>
      </el-menu-item>
      <el-menu-item index="/doctor">
        <i class="el-icon-s-help" />
        <span slot="title">医生管理</span>
      </el-menu-item>
      <el-menu-item index="/upgrade">
        <i class="el-icon-upload" />
        <span slot="title">固件升级</span>
      </el-menu-item>
      <!-- 耗材管理系统功能 -->

      <el-submenu index="/system">
        <template slot="title">
          <i class="el-icon-s-tools" />
          <span slot="title">系统管理</span>
        </template>
        <el-menu-item index="/system/staff">
          <i class="el-icon-" />
          <span slot="title">用户管理</span>
        </el-menu-item>
      </el-submenu>
      <div class="edition">V2.0.0</div>
      <div style="height: 32px" />
    </el-menu>
  </el-scrollbar>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.el-scrollbar {
  height: 100vh;
}
.el-scrollbar >>> .el-scrollbar__wrap {
  overflow-x: hidden;
}

.sidebar {
  min-height: 100vh;

  border-right: none;
}

.logo {
  height: 30px;

  margin: 25px auto;
}
.title {
  height: 80px;

  line-height: 80px;
  font-size: 25px;
  color: #ffffff;
}

li {
  text-align: left;
}

.el-menu-item:hover,
.el-submenu >>> .el-submenu__title:hover,
.el-submenu >>> .el-menu-item:hover {
  background: #262a39 !important;
}
.el-menu-item.is-active,
.el-submenu >>> .el-menu-item.is-active {
  background: #4165d7 !important;
}

.el-submenu svg,
.el-menu-item svg {
  margin: 0 8px 0 3px;
}
.is-active svg path {
  fill: #fff;
}

.edition {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  color: #fff;
  line-height: 2em;
}
</style>