// export let serverHttps = process.env.NODE_ENV !== "development" ? "https://iot.trial.uyun-iot.cn" : "http://192.168.0.125"
// export let serverWs = process.env.NODE_ENV !== "development" ? "wss://iot.trial.uyun-iot.cn" : "ws://192.168.0.125"

// export let serverHttps = "http://1.14.146.176:8095"
// export let serverWs = "ws://1.14.146.176:8095"

// 开发测试
export let serverHttps = "https://dailusha.asset.uyun-iot.cn"
export let serverWs = "wss://dailusha.asset.uyun-iot.cn"

// // //黛露莎
// export let serverHttps = "https://dailusha.asset.uyun-iot.cn"
// export let serverWs = "wss://dailusha.asset.uyun-iot.cn"