import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '',
    redirect: '/index',
},
{
    path: '/login',
    component: () => import('../views/user/Login.vue'),
    meta: {
        hidden: true,
    }
},
{
    path: '/index',
    component: () => import('../views/index/home/Home.vue'),
    meta: {
        keepAlive: true
    }
},
{
    path: '/myProfile',
    component: () => import('../views/user/info/Info.vue'),
    meta: {
        keepAlive: true
    }
},
{
    path: '/editPW',
    component: () => import('../views/user/editPW/EditPW.vue'),
    meta: {
        keepAlive: true
    }
},
{
    path: '/product',
    component: () => import('../views/main/product/home/Home.vue'),
    meta: {
        keepAlive: true
    }
},
{
    path: '/product/:id',
    component: () => import('../views/main/product/one/One.vue'),
},
{
    path: '/device',
    component: () => import('../views/main/device/home/Home.vue'),
    meta: {
        keepAlive: true
    }
},
{
    path: '/device/:id',
    component: () => import('../views/main/device/one/One.vue'),
},
{
    path: '/equipment',
    component: () => import('../views/main/equipment/home/Home.vue'),
    meta: {
        keepAlive: true
    }
},
{
    path: '/equipment/:id',
    component: () => import('../views/main/equipment/one/One.vue'),
},
{
    path: '/system/staff',
    component: () => import('../views/main/system/staff/home/Home.vue'),
    meta: {
        keepAlive: true
    }
},
{
    path: '/application',
    component: () => import('../views/main/application/home/Home.vue'),
    meta: {
        keepAlive: true
    }
},
{
    path: '/upgrade',
    component: () => import('../views/main/upgrade/home/Home.vue'),
    meta: {
        keepAlive: true
    }
},
{
    path: '/upgrade/:id',
    component: () => import('../views/main/upgrade/one/One.vue'),
},
{
    path: '/customer',
    component: () => import('../views/main/customer/home/Home.vue'),
    meta: {
        keepAlive: true
    }
},
{
    path: '/customer/:id',
    component: () => import('../views/main/customer/one/One.vue'),
},
{
    path: '/asset',
    component: () => import('../views/main/asset/home/Home.vue'),
    meta: {
        keepAlive: true
    }
},
{
    path: '/asset/:id',
    component: () => import('../views/main/asset/one/One.vue'),
},
{
    path: '/doctor',
    component: () => import('../views/main/doctor/home/Home.vue'),
    meta: {
        keepAlive: true
    }
},
{
    path: '/doctor/:id',
    component: () => import('../views/main/doctor/one/One.vue'),
},
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router