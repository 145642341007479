import axios from 'axios'

import {
  serverHttps
} from "./server";

export function request(config) {
  const instance = axios.create({
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem("token"),
    },
    // transformRequest: [function (data) {
    //   data = JSON.stringify(data);
    //   return data
    // }],
    baseURL: serverHttps,
    method: 'post',
    timeout: config.timeout || 5000
  });

  //请求拦截
  instance.interceptors.request.use(config => {
    return config
  }, error => {
    console.log(error)
  });

  //响应拦截
  instance.interceptors.response.use(res => {
    return res.data
  }, error => {
    if (error.message.includes('timeout')) {
      console.log("请求超时", error)
      return Promise.reject(error);
    }
    console.log(error)
    return error.request
  });

  return instance(config)
}